<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">限免45服开区活动</span>
        </div>

        <div class="main" style="padding: 10px 10px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start;font-size: 13px;color: #666666;text-align: left;">
                <span style="font-size: 16px;font-weight: bold;color: #f35815">活动1：首充返利</span>
                <span style="font-size: 12px;margin-top: 4px;color: #666666;font-weight: bold;">
                    限免·天使极速<span style="color: #f35815">45服</span>开服当天(24:00截止)，本区完成首笔充值且绑定微信的玩家即可参与。返利奖池总计10000元，抽完即止。
                </span>

                <div style="width: 100%; text-align: right;font-size: 12px;margin-top: 10px;display: flex;justify-content: space-between;padding: 0 5px;box-sizing: border-box;">
                    <span v-if="bindWxStatus === 1">还未绑定微信<span style="margin-left: 5px;color: #f35815" @click="goWxPage">去绑定</span></span>
                    <span v-if="bindWxStatus === 2">已绑定微信</span>
                    <span @click="openRecordPage" style="color: #f35815;cursor: pointer;">实时开奖记录<van-icon name="arrow" /></span>
                </div>

                <div style="width: 100%;display: flex;margin-top: 5px;background-color: #f6f2ee;padding: 12px 8px;border-radius: 4px;box-sizing: border-box;">
                    <div style="flex: 1; display: flex;flex-direction: column; align-items: center;width: 100%;">
                        <span style="">奖池剩余</span>
                        <div class="pro-pool-box">
                            <div class="pro-pool-data" :style="{'width': poolData + '%'}"></div>
                            <span style="z-index: 9;position: relative;font-size: 12px;">{{balance}}元</span>
                        </div>
                    </div>

                    <div style="flex: 1; display: flex;flex-direction: column; align-items: center;width: 100%;">
                        <span style="">我的首冲</span>
                        <div style="font-size: 22px;font-weight: bold;color: #f35815">
                            <span style="font-size: 12px;">￥</span>
                            <span>{{myFirstMoney}}</span>
                        </div>
                    </div>

                    <div v-if="firstStatus === 3" style="flex: 1; display: flex;flex-direction: column; align-items: center;width: 100%;">
                        <span style="">我的抽奖</span>
                        <div style="font-size: 22px;font-weight: bold;color: #f35815">
                            <span style="font-size: 12px;">￥</span>
                            <span>{{myRedMoney}}</span>
                        </div>
                    </div>

                    <div v-else style="flex: 1; display: flex;align-items: center;justify-content: center;">
                        <img v-if="firstStatus === 2 && bindWxStatus === 2" @click="drawFirst" src="../../img/draw-btn.png" style="width: 40px;height: 40px;">
                        <img v-else @click="drawDisable" src="../../img/draw-btn-disable.png" style="width: 40px;height: 40px;">
                    </div>

                </div>

            </div>

            <div style="border-top: 2px dashed #f84d4d;margin: 20px 10px;">
            </div>

            <div style="display: flex;flex-direction: column;align-items: flex-start;font-size: 13px;color: #666666;text-align: left;">
                <span style="font-size: 16px;font-weight: bold;color: #f35815">活动2：充值送草皇海神</span>
                <span style="font-size: 12px;margin-top: 4px;color: #666666;font-weight: bold">
                    开服当天（24:00截止）进入该服的玩家实充满<span style="color: #f35815">200元</span>送一件草皇+五件海神，实充满<span style="color: #f35815">800元</span>再送四件草皇。
                    登记后会在开服23-25天，57-59天分别发放草皇和海神，发放前7天均未登录视为放弃奖励。
                </span>

                <div style="width: 100%;margin-top: 5px;background-color: #f6f2ee;padding: 12px 8px;border-radius: 4px;box-sizing: border-box;">
                    <span>我的充值金额</span>
                    <div style="font-size: 22px;font-weight: bold;color: #f35815;margin-top: 3px;">
                        <span style="font-size: 12px;">￥</span>
                        <span>{{myRechargeNum}}</span>
                    </div>

                    <div class="pro-recharge-box">
                        <div class="pro-recharge-data" :style="{'width': rechargeData + '%'}"></div>
                        <div :style="myRechargeNum >= 200 ? 'color: #f35815' : 'color: #888888'" style="width: 1px;height: 10px;background-color: #f58b21;position: absolute;left: 25%;top: 14px; font-weight: bold;">
                            <div style="margin-top: 13px;margin-left: -10px;width: 40px;text-align: left;">200元</div>
                            <div style="margin-top: 1px;margin-left: -10px;width: 100px;text-align: left;">草皇x1 海神x5</div>
                        </div>

                        <div :style="myRechargeNum >= 800 ? 'color: #f35815' : 'color: #888888'" style="width: 1px;height: 10px;background-color: #f58b21;position: absolute;right: 0;top: 14px; font-weight: bold;">
                            <div style="margin-top: 13px;margin-left: -10px;width: 40px;text-align: left;">800元</div>
                            <div style="margin-top: 1px;margin-left: -10px;width: 50px;text-align: left;">草皇x4</div>
                        </div>
                    </div>

                    <div style="margin-top:70px;display: flex;justify-content: space-between;align-items: center;">
                        <img v-if="rechargeStatus === 1" src="../../img/get-btn-wdc.png" style="width: 110px;cursor: unset;">
                        <img v-if="rechargeStatus === 2" @click="getAward" src="../../img/get-btn.png" style="width: 110px;cursor: pointer;">
                        <img v-if="rechargeStatus === 3" src="../../img/get-btn-ydj.png" style="width: 110px;cursor: unset;">
                        <img v-if="rechargeStatus === 4" src="../../img/get-btn-yff.png" style="width: 110px;cursor: unset;">

                        <div v-if="rechargeStatus === 3 || rechargeStatus === 4" style="font-size: 12px;">
                            <div v-if="drawNum === 6">
                                已登记草皇x1 海神x5
                            </div>
                            <div v-if="drawNum === 10">
                                <div>已登记草皇x1 海神x5</div>
                                <div>已登记草皇x4</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="showLoading" style="position: fixed; top:0;left: 0; width: 100%;height: 100%;border-radius: 5px; ;display: flex;align-items: center;justify-content: center">
            <div style="width: 100px;height: 100px;border-radius: 5px; background-color: #444444;display: flex;align-items: center;justify-content: center">
                <van-loading type="spinner" />
            </div>
        </div>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import myloading from '@/components/myloading'
    import Toast from "vant/lib/toast";
    import '../../css/commonpage.css'
    import notice from '@/api/notice';

    export default {
        name: "sdk-new-server-active",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                from: '',
                poolData: 0,
                myRedMoney: 0,
                balance: 0,
                myFirstMoney: 0,
                firstStatus: 2,//1:未达标 2：可领取  3：已领取
                rechargeStatus: 0,//1:未达标 2：可领取  3：已领取 4 已发货
                myRechargeNum: 0,
                rechargeData: 0,
                drawNum: 0,
                bindWxStatus: 1,
            }
        },
        mounted() {
            this.from = localStorage.getItem('from');
            this.initData();
        },
        methods: {
            initData() {
                this.showLoading = true;

                let options = {
                    url:"/zfapp/get45WelfareInfo",
                    data: {}
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        console.log(res.data.data)
                        let data = res.data.data;
                        this.poolData = data.progress;
                        this.bindWxStatus = data.bindWxStatus;
                        this.myRedMoney = data.myRedMoney;
                        this.firstStatus = data.myRedFlag;
                        this.myFirstMoney = data.myFirstMoney;
                        this.myRechargeNum = data.myChargeMoney;
                        this.rechargeStatus = data.myHSFlag;
                        this.balance = data.balance;
                        this.drawNum = data.drawNum;

                        this.rechargeData = Math.min(this.myRechargeNum * 100 / 800, 100);

                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            drawFirst() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/get45Red",
                    data: {}
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('抽奖成功');
                        this.initData();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

            },
            drawDisable() {
                if (this.bindWxStatus === 1) {
                    Toast('请先绑定微信再参加活动');
                }
                else {
                    Toast('还没有进行首充，无法参与活动');
                }
            },
            getAward() {
                this.showLoading = true;
                let options = {
                    url:"/sdkH5/getChAndHst",
                    data: {}
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('领取成功');
                        this.initData();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            getAwardDisable() {
                Toast('条件未达标');
            },
            openRecordPage() {
                this.$parent.$parent.openFuliRecordPage();
            },
            goWxPage() {
                this.$parent.$parent.openWeixinPage();
            },
            back() {
                this.$parent.$parent.showMainMenu();
            },


        }
    }
</script>

<style scoped>

    .main {
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }

    .pro-pool-box {
        height: 12px;
        line-height: 12px;
        margin-top: 10px;
        width: 80px;
        border: 1px solid #ee520f;
        position: relative;
        background-color: rgba(238, 82, 15, 0.29);
        border-radius: 2px;
        color: #ffffff;
        text-align: center;
    }
    .pro-pool-data {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ee520f;
        height: 12px;
        width: 0;
    }

    .pro-recharge-box {
        width: 85%;
        height: 12px;
        line-height: 12px;
        margin-top: 10px;
        border: 1px solid #ee520f;
        background-color: rgba(238, 82, 15, 0.29);
        position: relative;
        border-radius: 2px;
        color: #ffffff;
        text-align: center;
        font-size: 12px;
    }
    .pro-recharge-data {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ee520f;
        height: 12px;
        width: 0;
    }
</style>