<template>
    <div class="common-body-view" style="height: 550px;">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
        </div>

        <div ref="content" class="main content__main">
            <div class="content__container">
                <div>
                    <span class="content__title">{{ contentDetailData.contentName }}</span>
                </div>
                <div>
                    <div style="height: fit-content" v-html="contentDetailData.contentText"></div>
                </div>
            </div>
            <div @click="redirect" style="font-size: 12px;color: #409EFF;cursor: pointer;">
                点击跳转浏览器打开
            </div>
        </div>
    </div>
</template>

<script>
import {request} from "@/api/request";
import notice from "@/api/notice";

export default {
    name: "contentView",
    props: {
        contentId: {
            type: String,
            default: ''
        },
        contentType: {
            type:String,
            default: '1'
        }
    },
    data(){
        return {
            contentDetailData: {},
        }
    },
    watch: {
        "contentId": {
            immediate: true,
            handler() {
                this.loadContentDetail();
                let content = document.getElementsByClassName("main-dialog")[0];
                content.style.height = "550px";
            },
        }
    },
    methods: {
        back() {
            let content = document.getElementsByClassName("main-dialog")[0];
            content.style.height = "310px";
            this.$parent.$parent.closeContentViewPage();
        },
        loadContentDetail(){
            let options = {
                url: `/content/getContentDetail`,
                data: {
                    contentId: this.contentId
                }
            }
            request(options).then(res => {
                if(res.data.success){
                    this.contentDetailData = res.data.data;
                }
            })
        },
        redirect() {
            this.$dialog.confirm({
                message: '确认在浏览器中打开吗？',
            }).then(() => {
                // on confirm
                let url = `https://ts.7li.cn/#/content?contentId=${this.contentId}`
                // let url = `http://192.168.68.55:8080/#/content?contentId=${this.contentId}`
                notice.startIntent(url);
            }).catch(() => {
                // on cancel
            });
        } 
    }
}
</script>

<style scoped>
.content__container::-webkit-scrollbar {
    display: none;
}
.content__container {
    width: 100%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    
    /*overflow: hidden;*/
    /*overflow-y: auto;*/
}
.content__main {
    border-radius: 8px;
    height: calc(100% - 50px);
}
.content__title {
    font-weight: 700;
    font-size: 24px;
}
</style>