<template>
    <div class="content__main">
        <div class="content__container">
            <div>
                <span class="content__title">{{ contentDetailData.contentName }}</span>
            </div>
            <div>
                <div style="height: fit-content" v-html="contentDetailData.contentText"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {request} from "@/api/request";

export default {
    name: "ContentTemp",
    data() {
        return {
            contentDetailData: {
                contentName: '',
                contentText: ''
            },
            contentId: '',
        }
    },
    mounted() {
        this.contentId = this.$route.query.contentId;
    },
    watch: {
        contentId: {
            immediate: true,
            handler() {
                if(this.contentId) {
                    this.loadContentDetail();
                }
            }
        }
    },
    methods: {
        loadContentDetail(){
            let options = {
                url: `/content/getContentDetail`,
                data: {
                    contentId: this.contentId
                }
            }
            request(options).then(res => {
                if(res.data.success){
                    this.contentDetailData = res.data.data;
                }
            })
        },
    }
}
</script>

<style scoped>
.content__main {
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow: auto;
}
.content__container {
    //width: 100%;
}
.content__title {
    font-weight: 700;
    font-size: 24px;
}
</style>