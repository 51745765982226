<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">实时开奖记录</span>
        </div>

        <div style="position: absolute;right: 30px;top: 10px;">
            <img @click="queryData" src="../../img/refresh.png" style="width: 30px;cursor: pointer;">
        </div>

        <div class="main" style="padding: 5px 10px;">
            <div v-if="recordList.length === 0" style="background-color: #FFFFFF;margin-top: 20px;height: 80px;display: flex;justify-content: center;align-items: center;border-radius: 5px;">
                <span class="none-text">暂无抽奖记录</span>
            </div>

            <div style="width: 100%">
                <div v-for="(item,index) in recordList" style="margin-top: 1px;" class="record-list">
                   <div style="display: flex;">
                       <span style="background-color: #ef561e;color: #FFFFFF;padding: 2px 5px;font-size: 13px;font-weight: bold;border-radius: 2px;">
                           {{item.index}}
                       </span>
                       <span style="font-size: 13px;color: #ef561e;font-weight: bold;margin-left: 5px;"> {{item.getRole}}</span>
                   </div>

                    <div style="font-size: 12px;color: #666666;margin-top: 5px;text-align: left;display: flex;justify-content: space-between;width: 100%;align-items: center;">
                        <div style="display: flex;flex-direction: column; align-items: center;">
                            <span style="">首冲</span>
                            <div style="font-size: 18px;font-weight: bold;color: #888888">
                                <span style="font-size: 12px;">￥</span>
                                <span>{{item.firstMoney}}</span>
                            </div>
                        </div>

                        <div style="display: flex;flex-direction: column; align-items: center;">
                            <span style="">返利</span>
                            <div style="font-size: 18px;font-weight: bold;color: #f35815">
                                <span style="font-size: 12px;">￥</span>
                                <span>{{item.money}}</span>
                            </div>
                        </div>

                        <div style="text-align: center">
                            <div style="font-size: 12px;color: #888888;">{{item.time}}</div>
                            <div style="font-size: 12px;color: #888888;">{{item.ipInfo}}</div>
                        </div>
                    </div>
                </div>
            </div>


            <div v-show="showLoading" style="position: fixed; top:0;left: 0; width: 100%;height: 100%;border-radius: 5px; ;display: flex;align-items: center;justify-content: center">
                <div style="width: 100px;height: 100px;border-radius: 5px; background-color: #444444;display: flex;align-items: center;justify-content: center">
                    <van-loading type="spinner" />
                </div>
            </div>

<!--            <myloading v-show="showLoading"></myloading>-->
        </div>


    </div>
</template>

<script>
import myloading from "@/components/myloading.vue";
import {Toast} from "vant";
import {request} from "@/api/request";

export default {
    name: "NewServerRecord",
    components: {myloading},
    data(){
        return {
            recordList: [],
            showLoading: false,
        }
    },
    mounted() {
        this.queryData();
    },
    methods: {
        back() {
            this.$parent.$parent.openFuliPage();
        },
        queryData() {
            this.showLoading = true;
            let options = {
                url:"/zfapp/get45RedLogList",
                data: {}
            };
            request(options).then(res => {
                this.showLoading = false;
                if (res.data.success) {
                    this.recordList = res.data.data;
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
    }
}
</script>

<style scoped>
    .main{
        overflow-y: auto;
    }
    .none-text{
        font-size: 16px;
        color: #888888;
        text-align: center;
    }
    .record-list{
        background-color: #f6f2ee;
        border-radius: 5px;
        padding: 8px 10px;
        text-align: left;
    }
</style>