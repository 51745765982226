import axios from './axios'

export async function request (options) {
  let systemInfo = JSON.parse(localStorage.getItem("systemInfo"));
  if(systemInfo) {
    options.data.commonParam = {
      device: systemInfo.device,
      systemVersion: systemInfo.systemVersion,
      udid: systemInfo.udid,
      channelId: systemInfo.channelId,
      gameId: systemInfo.gameId,
      sdkVersion: systemInfo.sdkVersion,
      time: new Date().getTime()
    };
  }

  return axios({
    url: options.url,
    method: 'post',
    data: options.data
  })
}

export async function get(url) {
  return axios({
    url: url,
    method: 'get'
  })
}
