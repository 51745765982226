<template>

    <div class="main"  id="mainView">

        <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="no" border="0"  scrolling="no" width="100%" height="100%"></iframe>

        <div v-show="false">
            <van-count-down
                    ref="countDown"
                    :time="time"
                    :auto-start="false"
                    format="ss"
                    @finish="finish"/>
        </div>

        <div v-if="iOSCopyButtonShow || transformGameButtonShow" style="padding: 8px 15px; background-color: #ffffff;border-radius: 5px; position: absolute;top: 260px;
                left: 0;z-index: 99;display: flex;flex-direction: column;align-items: flex-start;border: 1px solid #eeeeee;box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1);">

            <div style="font-size: 12px;">当前通行证: {{codeValue}}</div>
            <div v-if="iOSCopyButtonShow" @click="doCopyCode" style="color: #ffffff;background-color: #0f78e8;border-radius: 5px;width: 90px;font-size: 12px;padding: 8px 10px;margin-top: 15px;">
                复制通行证
            </div>
            <div v-if="transformGameButtonShow"  @click="transformGamePage"  style="color: #ffffff;padding: 8px 10px;width: 90px;font-size: 12px; background-color: #f13a3a;border-radius: 5px;margin-top: 15px; ">
                <span style="color: #ffffff">通行证进入游戏</span>
            </div>
            <div @click="txzTipsShow = true" style="color: #0f78e8;width: 90px;font-size: 12px;padding: 8px 10px;margin-top: 5px;">
                加速器使用教程
            </div>
        </div>

        <div v-if="codePageShow" ref="codePageRef" style="width: 50%;height: 260px;position: absolute;top: 0;left: 0;" >
            <iframe v-show="true" allowfullscreen ref="codePageIframe" id="codePageIframe" :src="codePageUrl" frameborder="0" scrolling="no" width="100%"
                    height="100%">
            </iframe>
        </div>

        <van-popup  v-model="txzTipsShow" v-if="txzTipsShow" position="center" :style="{ width: '90%'}" style="background: transparent;max-width: 350px;">
            <!--<div v-if="isIOS" style="font-size: 12px;text-align: left;padding: 0 15px;display: flex;flex-direction: column;align-items: flex-start;">
                <span style="margin-top: 5px;">1. 点击浮窗页面中的“复制通行证”按钮，将通行证复制到剪贴板</span>
                <span style="margin-top: 5px;">2. 点击游戏右上角“通行证”按钮，打开通行证输入窗口</span>
                <span style="margin-top: 5px;">3. 将复制好的通行证粘贴到输入框，点击确认</span>
                <span style="margin-top: 5px;">4. 此时下方通行证页面已进入区服选择页面，点击浮窗页面“通行证进入游戏”按钮</span>
                <span style="margin-top: 5px;">5. 此时区服选择页面已经全屏，正常进入游戏即可</span>
            </div>
            <div v-else style="font-size: 12px;text-align: left;padding: 0 15px;display: flex;flex-direction: column;align-items: flex-start;">
                <span style="margin-top: 5px;">1. 点击游戏右上角“通行证”按钮，打开通行证输入窗口</span>
                <span style="margin-top: 5px;">2. 将系统自动复制好的通行证粘贴到输入框，点击确认</span>
                <span style="margin-top: 5px;">3. 此时下方通行证页面已进入区服选择页面，点击浮窗页面中的“通行证进入游戏”按钮</span>
                <span style="margin-top: 5px;">4. 此时区服选择页面已经全屏，正常进入游戏即可</span>
            </div>-->

            <video src="https://cdn.7li.club/pc/jsjc.mp4"  style="width: 100%;border-radius: 8px;" :controls="true" :autoplay="true" ></video>
            <div @click="txzTipsShow = false" style="font-size: 14px;font-weight: bold; height: 40px;line-height: 40px;background: #ef581b;color: #ffffff;border-radius: 40px;padding: 0 20px;width: 120px;margin: 0 auto;">
                我学会了
            </div>
        </van-popup>

<!--        <div v-if="iOSCopyButtonShow" @click="doCopyCode" style="padding: 5px 10px; background-color: #0f78e8;border-radius: 5px; position: absolute;bottom: 240px;right: 20px;">


            <span style="color: #ffffff">复制通行证 <br><span style="font-weight: bold">{{codeValue}}</span></span>
        </div>

        <div v-if="transformGameButtonShow" @click="transformGamePage" style="padding: 15px 10px; background-color: #f13a3a;border-radius: 5px; position: absolute;bottom: 140px;right: 20px;">
            <span style="color: #ffffff">通行证进入游戏</span>
        </div>-->

        <div class="qili-icon" id="moveDiv"
             @mousedown="down($event)"
             @touchstart="down($event)"
             @mousemove="move($event)"
             @touchmove="move($event)"
             @mouseover="end($event)"
             @mouseleave="move($event)"
             @mouseup="end($event)"
             @touchend="end($event)"
             @click="showMainWindow">

            <img src="../../static/main-round.png"  width="50px" height="50px">
        </div>

        <van-notice-bar  v-if="showNotice" delay="0"  :style="noticeStyle" style="color: #eca007;pointer-events: none;position: absolute;top: 100px;left: 50%; transform: translate(-50%, -50%); font-size: 14px;width: 100%;max-width: 450px; height: 35px;background-color: rgba(0,0,0,.4)"
                         speed="30"  :text="noticeMessage" @replay="hideNotice"/>

        <van-dialog :style="dialogStyle" :close-on-click-overlay="true" class="main-dialog" v-model="floatDialogShow" v-if="floatDialogShow" :showConfirmButton="false">
            <mainmenu ref="mainDialog" v-if="action === 1"></mainmenu>
            <kf v-if="action === 2"></kf>
            <password ref="passwordDialog" v-if="action === 3"></password>
            <recharge ref="rechargeDialog" v-if="action === 4"></recharge>
            <tel v-if="action === 5"></tel>
            <telunbind v-if="action === 6"></telunbind>
            <weixin v-if="action === 7"></weixin>
            <gift v-if="action === 8"></gift>
            <giftrecord v-if="action === 9"></giftrecord>
            <expense v-if="action === 10"></expense>
            <gamespeed v-if="action === 11"></gamespeed>
            <chat v-if="action === 12"></chat>
            <trade v-if="action === 13"></trade>
            <content-list :content-type="contentType" v-if="action === 14"></content-list>
            <content-view :content-id="contentId" :content-type="contentType"  v-if="action === 15"></content-view>
            <new-server-active v-if="action === 16"></new-server-active>
            <new-server-record v-if="action === 17"></new-server-record>
        </van-dialog>

        <van-popup :style="dialogStyle" v-model="payShow" v-if="payShow" class="pay-dialog" >
            <pay ref="pay"></pay>
        </van-popup>


        <van-popup v-model="gcGameShow" position="top" :style="{ width: '100%', height: '100%' }" >
            <temp-game ref="gcGame"></temp-game>
        </van-popup>

        <div class="diy-dialog" v-show="payConfirmShow">
            <div style="text-align: center">信息</div>
            <van-divider :style="{ margin: '10px 0' }"/>
            <div style="height: 80px;padding: 0 15px;">您将打开支付页面，请问是否继续？<br><br>
                <span style="color: #b3b3b6; font-size: 13px;">注：支付成功后请关闭新打开的支付窗口</span>
            </div>
            <van-divider :style="{ margin: '10px 0 0' }"/>
            <div class="diy-dialog-buttons">
                <div style="flex: 1;text-align: center">
                    <a @click="payConfirmShow = false">取消</a>
                </div>
                <div style="height: 100%;width: 1px;background-color: #ebedf0"></div>
                <div style="flex: 1;text-align: center">
                    <a target="_blank" style="color: #1783d6" :href="payUrl" @click="payConfirmShow = false">继续</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import { Popover } from 'vant';
    Vue.use(Popover);

    import {request} from '@/api/request';
    import {Toast} from 'vant';
    import {Dialog} from 'vant';
    import myloading from '@/components/myloading';
    import myemoji from '@/components/myemoji';

    import mainmenu from '@/components/jxf/MainMenu';
    import kf from '@/components/jxf/Kf';
    import password from '@/components/jxf/Password';
    import recharge from '@/components/jxf/Recharge';
    import tel from '@/components/jxf/Tel';
    import telunbind from '@/components/jxf/TelUnbind';
    import weixin from '@/components/jxf/Weixin';
    import gift from '@/components/jxf/Gift';
    import giftrecord from '@/components/jxf/GiftRecord';
    import expense from '@/components/jxf/ExpenseCalendar';
    import gamespeed from '@/components/jxf/GameSpeed';
    import chat from '@/components/jxf/Chat';
    import trade from '@/components/jxf/Trade';
    import pay from '@/components/jxf/Pay';

    import TempGame from "@/page/temp/TempGame.vue";
    import timerhook from '@/utils/timerhook';
    import ContentList from "@/components/jxf/ContentList.vue";
    import ContentView from "@/components/jxf/ContentView.vue";
    import newServerActive from "@/components/jxf/NewServerActive.vue";
    import newServerRecord from "@/components/jxf/NewServerRecord.vue";

    export default {
        name: "jxf-game",
        components: {
            ContentView,
            ContentList,
            myloading,
            myemoji,
            mainmenu,
            kf,
            password,
            recharge,
            tel,
            telunbind,
            weixin,
            gift,
            giftrecord,
            expense,
            gamespeed,
            chat,
            trade,
            TempGame,
            pay,
            newServerActive,
            newServerRecord,
        },
        data() {
           return {
               action: 1,
               payShow: false,
               floatDialogShow: false,
               isLogout: false,
               mainMenuShow: false,
               telShow: false,
               telUnbindShow: false,
               kfShow: false,
               diyDialogShow: false,
               passwordShow: false,
               rechargeShow: false,
               giftShow: false,
               giftRecordShow: false,
               expenseShow: false,
               gameSpeedShow: false,
               chatShow: false,
               tradeShow: false,
               contentListShow: false,
               contentViewShow: false,

               payConfirmShow: false,
               gcGameShow: false,

               payDialogShow: false,
               payCodeShow: false,
               iframeDialogShow: false,
               weixinShow: false,
               hasRedShow: false,

               flags: false,
               position: {x: 0, y: 0},
               nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
               isShow: false,

               channelId: '',
               gameId: '',
               from: '',

               buttonHidden: true,
               halfOffset: 0,
               time: 10000,
               firstClick: true,
               gameUrl: '',
               tempGameUrl: '',
               payUrl: '',
               screenModel: 1,//当前屏幕方向 1-竖屏 2-横屏
               screenType: 0,//游戏类型 1-竖屏 2-横屏
               dialogStyle: {},
               openPageUrl: '',//外部链接地址

               path: location.protocol + '//' + location.host + '/#' + this.$route.path, //当前页面url
               speedRate: 1,
               drawUrl: '',
               hasRed: false,
               shoutId: 0,
               shoutMsgList: [],
               showNotice: false,
               noticeStyle: '',
               noticeMessage: '',

               codePageShow: false,
               codePageUrl: '',
               codeValue: '',
               iOSCopyButtonShow: false,
               transformGameButtonShow: false,
               txzTipsShow: false,
               isIOS: false,

               contentType: '',
               contentId: ''
           }
        },
        mounted() {
            console.log('......主页面初始化');
            if (!localStorage.getItem('token') || !localStorage.getItem('userInfo')) {
                this.$router.replace("/login?gameId=397");
                return ;
            }
            this.isIOS = this.getIsIOS();
            localStorage.removeItem('login_status');
            this.isApp = localStorage.getItem('isApp');
            this.from = localStorage.getItem('from');
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let systemInfo = JSON.parse(localStorage.getItem('systemInfo'));
            this.channelId = systemInfo.channelId;
            this.gameId = systemInfo.gameId;
            this.codePageUrl = localStorage.getItem('codePageUrl');
            if (this.gameId === '397' &&  this.codePageUrl) {
                this.codePageShow = true;
                localStorage.removeItem('codePageUrl');
                if (this.isIOS) {
                    setTimeout(() => {
                        this.getAndCopyCode() ;
                        this.iOSCopyButtonShow = true;
                    }, 2000)
                }
                else {
                    setTimeout(() => {
                        this.getAndCopyCode() ;
                        Toast('通行证复制成功');
                        this.transformGameButtonShow = true;
                    }, 2000)
                }
            }

            this.gameUrl = this.userInfo.gameUrl;
            //this.gameUrl = 'https://www.baidu.com';
            this.uid = this.userInfo.userId;
            this.bottomMsg = this.userInfo.flMsg;
            window.addEventListener("message", this.listener);
            this.reportHeartbeat();
            this.showShoutMsg();
        },
        beforeDestroy() {
           window.removeEventListener("message", this.listener);
        },
        destroy() {

        },
        methods: {
            transformGamePage() {
                this.$refs.codePageRef.style.height = "100vh";
                this.$refs.codePageRef.style.width = "100vw";
                this.transformGameButtonShow = false;
            },
            doCopyCode() {
                this.copyCodeForIOS(this.codeValue);
                Toast('通行证复制成功');
                setTimeout(() => {
                    this.iOSCopyButtonShow = false;
                    this.transformGameButtonShow = true;
                }, 1000)
            },
            copyCodeForIOS(value, type = 'input') {
                const input = document.createElement(type);
                input.setAttribute('readonly', 'readonly'); // 设置为只读, 防止在 ios 下拉起键盘
                input.value = value;
                document.body.appendChild(input);
                input.setSelectionRange(0, 9999); // 防止 ios 下没有全选内容而无法复制
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            },
            copyCode(code) {
                const input = document.createElement('input')
                document.body.appendChild(input)
                input.setAttribute('value', code)
                input.select()
                if (document.execCommand('copy')) {
                    document.execCommand('copy')
                }
                document.body.removeChild(input);
                Toast('通行证复制成功');
            },
            getAndCopyCode() {
                try {
                    const iframe = document.getElementById('codePageIframe');
                    const doc = iframe.contentDocument || iframe.contentWindow.document;
                    let div = doc.getElementById("qrcode");
                    if (!!div) {
                        this.codeValue = div.title;
                        console.log('codeValue', this.codeValue);
                        if(!this.isIOS) {
                            this.copyCode(this.codeValue);
                        }
                    }
                    else {
                        this.codeValue = '';
                    }
                } catch (e) {
                    console.log('exception', e)
                    this.codeValue = '';
                }
            },
            getIsIOS() {
                return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 检测是否为ios
            },
            openIOSPayConfirm(url) {
                this.payUrl = url;
                this.payConfirmShow = true;
            },
            reportHeartbeat() {
                if (this.isLogout) return;
                let options = {
                    url:"/zfapp/heartBeatForTSJX",
                    data: {
                        shoutId: this.shoutId
                    }
                };
                request(options).then(res => {
                    let c = false;
                    if (res.data.success) {
                        let has = res.data.data.redStatus;
                        this.hasRed = has === '1';
                        if (this.hasRed  && this.redIcon === 2) {
                            this.redIcon = 3;
                        }

                        let msgList = res.data.data.shoutMsgList;
                        for (let i = 0; i < msgList.length; i++) {
                            let msg = msgList[i];
                            this.shoutId = msg.id;
                            this.shoutMsgList.push(msg);
                        }
                        c = true;
                    }
                    else {
                        console.log(res.data.error.message);
                        if (res.data.error.code !== '911') {
                            c = true;
                        }
                    }

                    if (c) {
                        setTimeout(() => {
                            if (!this.isLogout) {
                                this.reportHeartbeat();
                            }
                        }, 15000);
                    }

                });
            },
            hideNotice() {
                setTimeout(() => {
                    this.showNotice = false;
                    this.showShoutMsg();
                }, 1000)
            },
            showShoutMsg() {
                if (this.shoutMsgList.length > 0) {
                    let msg = this.shoutMsgList[0];
                    if (this.shoutMsgList.length > 1) {
                        this.shoutMsgList = this.shoutMsgList.slice(1);
                    }
                    else {
                        this.shoutMsgList = [];
                    }

                    let msgResult = '';
                    if (msg.msgType === 1) {
                        this.noticeStyle = '';
                        msgResult = `[${msg.serverName}] ${msg.roleName} ：${msg.msg}`;
                    }
                    else if (msg.msgType === 2) {
                        this.noticeStyle = 'font-weight: bold; color: #E74C3C; ';
                        msgResult = `官方通知： ${msg.msg}`;
                    }
                    else {
                        msgResult = `${msg.msg}`;
                    }

                    this.noticeMessage = '                                                                                                    ' + msgResult;
                    this.noticeShowTime = new Date().getTime();
                    this.showNotice = true;
                    console.log('开始播放时间', this.noticeShowTime)
                }
                else {
                    setTimeout(() => {
                        this.showShoutMsg();
                    }, 3000);
                }
            },
            setSpeed(n) {
                this.speedRate = n;
                timerhook.setSpeed(n);
                //Toast('游戏当前加速倍率：' + n);
            },
            listener(event) {
                //console.log('收到游戏通知:', event);
                if (event.data.operation === 'pay') {
                    console.log("触发支付");
                    let order = event.data.param;
                    this.openPayPage(order);
                }
                else if (event.data.operation === 'uploadGameRole') {
                    console.log("触发角色上报");
                    let param = event.data.param;
                    this.uploadRole(param);
                }
                else if (event.data.operation === 'brGameRole') {//开启加速版本角色上报
                    console.log("js触发角色上报");
                    let param = event.data.roleParam;
                    this.uploadRole(param);
                }
                else if (event.data.operation === 'repeatLogin') {
                    console.log("触发登陆被顶");
                    this.repeatLogin();
                }
            },
            openPayPage(order) {
                this.payShow = true;
                this.$nextTick(function () {
                    this.$refs.pay.initOrder(order);
                })
            },
            uploadRole(roleParam) {
                let options = {
                    url:"/zfapp/roleSync",
                    data: {
                        remark: roleParam.remark,
                        roleId: roleParam.roleId,
                        roleLevel: "",
                        roleName: roleParam.roleName,
                        serviceId: roleParam.serverId,
                        serviceName: roleParam.serverName,
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        console.log('角色信息上报成功');
                    }
                    else {
                        console.log('角色信息上报失败: ' + res.data.error.message);
                    }
                });
            },
            repeatLogin() {
                this.isLogout = true;
                localStorage.removeItem('userInfo');
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('speedRate');
                localStorage.setItem('login_status', 'logout');
                this.$router.replace("/login");
            },
            showMainWindow() {
                if (this.buttonHidden) {
                    this.activeButton();
                    this.start();
                    return;
                }
                this.closeAll();
                this.floatDialogShow = true;
                this.mainMenuShow = true;
                this.$nextTick(function () {
                    //this.$refs.mainDialog.getBalance();
                })
            },
            closeMainWindow() {
                this.floatDialogShow = false;
                this.activeButton();
                this.start();
            },
            closeAll() {
                this.telShow = false;
                this.telUnbindShow = false;
                this.kfShow = false;
                this.diyDialogShow = false;
                this.passwordShow = false;
                this.rechargeShow = false;
                this.giftShow = false;
                this.giftRecordShow = false;
                this.expenseShow = false;
                this.gameSpeedShow = false;
                this.chatShow = false;
                this.tradeShow = false;
            },
            openPayUrl(url, payType, price, oPrice, orderId) {
                this.payShow = false;
                if (this.isMobile()) {
                    this.payUrl = url;
                    let ua = navigator.userAgent.toLowerCase();
                    if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                        this.diyDialogShow = true;
                    } else {
                        window.open(this.payUrl, '');
                    }
                } else {
                    this.payCodeShow = true;
                    this.$nextTick(function () {
                        let data = {
                            payCode: url,
                            payType: payType,
                            payPrice: price,
                            payOriginalPrice: oPrice,
                            orderId: orderId,
                        }
                        this.$refs.payCode.init(data);
                    });
                }
            },
            closeDiyDialog() {
                this.diyDialogShow = false;
            },
            isMobile() {
                return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            },
            closePayPage() {
                this.payShow = false;
            },
            logout() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/logout",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.isLogout = true;
                        localStorage.removeItem('userInfo');
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        localStorage.setItem('login_status', 'logout');

                        if (this.from === 'qiliApp') {
                            this.sendUniMessage('back', '');
                        }
                        else {
                            this.$router.replace("/login");
                        }


                        //window.location.reload();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

            },
            sendUniMessage(type, m) {
                uni.postMessage({
                    data: {
                        action: type,
                        msg: m
                    }
                });
            },
            openFuliPage () {
                this.action = 16;
            },
            openFuliRecordPage () {
                this.action = 17;
            },
            openGiftPage(){
                // this.mainMenuShow = false;
                // this.giftShow = true;
                this.action = 8;
            },
            showMainMenu(){
                // this.giftShow = false;
                // this.mainMenuShow = true;
                this.action = 1;
            },
            openGiftRecordPage() {
                // this.giftShow = false;
                // this.giftRecordShow = true;
                this.action = 9;
            },
            closeGiftRecordPage() {
                // this.giftRecordShow = false;
                // this.giftShow = true;
                this.showMainMenu();
            },
            openExpensePage() {
                // this.mainMenuShow = false;
                // this.expenseShow = true;
                this.action = 10;
            },
            closeExpensePage() {
                // this.expenseShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openWeixinPage() {
                // this.mainMenuShow = false;
                // this.weixinShow = true;
                this.action = 7;
            },
            closeWeixinPage() {
                // this.weixinShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openKfPage() {
                // this.mainMenuShow = false;
                // this.kfShow = true;
                this.action = 2;
            },
            closeKfPage() {
                // this.kfShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openRechargePage() {
                // this.mainMenuShow = false;
                // this.rechargeShow = true;
                this.action = 4;
            },
            closeRechargePage() {
                // this.rechargeShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openTelPage() {
                // this.mainMenuShow = false;
                // this.telShow = true;
                this.action = 5;
            },
            closeTelPage() {
                // this.telShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openTelUnbindPage() {
                // this.mainMenuShow = false;
                // this.telUnbindShow = true;
                this.action = 6;
            },
            closeTelUnbindPage() {
                // this.telUnbindShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openPasswordPage() {
                // this.mainMenuShow = false;
                // this.passwordShow = true;
                this.action = 3;
            },
            closePasswordPage() {
                // this.passwordShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openGameSpeedPage() {
                // this.mainMenuShow = false;
                // this.gameSpeedShow = true;
                this.action = 11;
            },
            closeGameSpeedPage() {
                // this.gameSpeedShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openChatPage() {
                // this.mainMenuShow = false;
                // this.chatShow = true;
                this.action = 12;
            },
            closeChatPage() {
                // this.chatShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openTradePage() {
                // this.mainMenuShow = false;
                // this.tradeShow = true;
                this.action = 13;
            },
            closeTradePage() {
                // this.tradeShow = false;
                // this.mainMenuShow = true;
                this.showMainMenu();
            },
            openContentListPage(contentType) {
                this.contentType = contentType;
                // this.mainMenuShow = false;
                // this.contentListShow = true;
                this.action = 14;
            },
            closeContentListPage() {
                // this.mainMenuShow = true;
                // this.contentListShow = false;
                this.showMainMenu();
            },
            openContentViewPage(contentId) {
                this.contentId = contentId;
                // this.mainMenuShow = false;
                // this.contentListShow = false;
                // this.contentViewShow = true;
                this.action = 15;
            },
            closeContentViewPage() {
                // this.contentListShow = true;
                // this.contentViewShow = false;
                this.closeContentListPage();
            },

            toGcGamePage(){
                this.gcGameShow = true;
                this.$nextTick(function (){
                    this.$refs.gcGame.createIframe();
                });
            },
            closeGcGame(){
                this.gcGameShow = false;
            },

            openBindWeixinPageFromPay() {
                this.payShow = false;
                this.closeAll();
                this.floatDialogShow = true;
                this.mainMenuShow = false;
                this.weixinShow = true;
            },

            start() {
                this.$refs.countDown.reset();
                this.$refs.countDown.start();
            },
            reset() {
                this.$refs.countDown.reset();
            },
            finish() {
                this.unActiveButton();
            },
            down(event) {
                this.flags = true;
                let touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.position.x = touch.clientX;
                this.position.y = touch.clientY;
                this.dx = moveDiv.offsetLeft;
                this.dy = moveDiv.offsetTop;
            },
            move(event) {
                if (this.flags) {
                    if (this.buttonHidden) {
                        this.activeButton();
                    }
                    let touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    this.nx = touch.clientX - this.position.x;
                    this.ny = touch.clientY - this.position.y;
                    this.xPum = this.dx + this.nx;
                    this.yPum = this.dy + this.ny;
                    moveDiv.style.left = this.xPum + "px";
                    moveDiv.style.top = this.yPum + "px";
                    //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                    document.addEventListener("touchmove", function () {
                        event.preventDefault();
                    }, false);
                }
            },
            end(event) {
                this.flags = false;
                if (!this.xPum) {
                    return;
                }

                let {
                    clientHeight: windowHeight,
                    clientWidth: windowWidth
                } = document.documentElement;
                // 计算后坐标  设置 按钮位置
                //this.halfOffset = -moveDiv.clientWidth / 2;
                if (this.yPum > 50 && this.yPum < (windowHeight - 150)) { //  不在顶部 且 不在底部
                    if (this.xPum <= (windowWidth / 2)) {  //  left 小于等于屏幕一半
                        moveDiv.style.left = 0;
                        moveDiv.style.right = 'auto';
                        //this.hiddenOnLeft();
                        this.start();
                    } else { //  left 大于屏幕一半
                        moveDiv.style.left = 'auto';
                        moveDiv.style.right = 0;
                        this.start();
                    }
                } else {
                    if (this.yPum <= 50) {  //  在顶部
                        moveDiv.style.top = 0;
                        moveDiv.style.bottom = 'auto';
                        this.start();
                    } else if (this.yPum >= (windowHeight - 150)) {
                        moveDiv.style.bottom = 0;
                        moveDiv.style.top = 'auto';
                        this.start();
                    }
                    if (this.xPum < 0) {
                        moveDiv.style.left = 0;
                        this.start();
                    } else if (this.xPum > (windowWidth - moveDiv.clientWidth)) {
                        moveDiv.style.right = 0;
                        moveDiv.style.left = 'auto';
                        this.start();
                    }
                }
            },
            activeButton() {
                //moveDiv.style.opacity = 1;
                this.buttonHidden = false;
                if (!moveDiv.style.left) {
                    moveDiv.style.left = "0px"
                }

                if (moveDiv.style.left === '-25px') {
                    moveDiv.style.left = "0px"
                } else if (moveDiv.style.right === '-25px') {
                    moveDiv.style.right = "0px"
                }
                /*moveDiv.style.transform = `translate3d(${this.halfOffset+'px'}, 0, 0)`;
                moveDiv.style.transition = 'transform 1s linear 0s';*/
            },
            unActiveButton() {
                //moveDiv.style.opacity = 0.2;
                this.buttonHidden = true;
                if (moveDiv.style.left === '0px') {
                    moveDiv.style.left = "-25px"
                } else if (moveDiv.style.right === '0px') {
                    moveDiv.style.right = "-25px"
                }

                /*moveDiv.style.transform = `translate3d(${this.halfOffset+'px'}, 0, 0)`;
                moveDiv.style.transition = 'transform 1s linear 0s';*/
            },
            onClose() {
                console.log("关闭")
            }

        }
    }
</script>

<style scoped>
    .main {
        background-color: #ffffff;
        width: 100vw;
        height: 100vh;
        padding: 0;
        overflow: hidden;
    }
    .main-dialog {
        width: 88%;
        max-width: 300px;
        height: 310px;
        box-sizing: border-box;
    }

    .pay-dialog {
        background: transparent;
        width: 90%;
        max-width: 300px;
    }

    .qili-icon {
        position: fixed;
        touch-action: none;
        height: 50px;
        width: 50px;
        /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
        z-index: 999;
        top: 60%;
        left: -25px;
    }

    .diy-dialog {
        background-color: #FFFFFF;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 8px 1px 8px;
        text-align: left;
        font-size: 14px;
        width: 80%;
        border-radius: 10px;
        left: 10%;
        z-index: 99999;
        top: 30%;
    }

    .diy-dialog-buttons {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        height: 40px;
    }

    /deep/.van-notice-bar__content{
        white-space:pre;
    }
</style>